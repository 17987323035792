import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function NeckPain() {
    return (
        <Layout>
            <Helmet>
                <title>Neck and Head Pain - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Neck and Head Pain</h1>
            </Title>
            <section>
                <Text>
                    <p>Neck pain is common in people of all ages. There can be a variety of reasons for this discomfort. Working all day bent over a computer, driving long distances, poor posture while standing or sitting, stress and tiredness are all factors that can cause the muscles in the neck and upper back to become tight and the joints to become stiff, which can contribute to ongoing neck pain.</p>
                    <p>Sometimes a nerve in your neck can become irritated or “trapped” and cause pain in the arm going down into your shoulder or the hand, and may be accompanied by pins and needles and numbness. Some headaches can be the result of tension or stiffness in the neck and upper back. Natural age-related wear and tear (osteoarthritis), does not necessarily lead to neck discomfort, however coupled with other factors, it can lead to muscular pain from the neck into the shoulder as well as some stiffness in moving the neck.</p>
                    <p>There are several reasons for headaches. Most are not serious and once the cause is established headaches can often be helped by simple changes in lifestyle. One cause can be tension or strain in the muscles and joints of the neck and upper back.</p>
                    <p>Treatment from an osteopath may help. Gentle massage to the tight muscles and manipulation to loosen the joints of the neck, thorax and back can relieve the build-up of muscular tension that may lead to headaches. Osteopaths can also advise on exercise and lifestyle changes and offer guidance on simple changes to your posture when at work or driving which may help.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can an Osteopath Help?</h2>
                    </Underline>
                    <p>Our Osteopaths can use a wide range of gentle hands-on techniques depending on your age, fitness and diagnosis to reduce muscular tension in the neck and improve movement in the joints of the neck and upper back.</p>
                    <p>Management is tailored to each individual and sometimes it might involve other areas in the back and shoulders as well as the neck.</p>
                    <p>Advice on posture at work or in the car, on exercise and stretching to help keep your neck and upper back muscles and joints relaxed.</p>
                    <p>X-rays, scans and other tests are sometimes required to make a diagnosis. An osteopath may refer you to your GP or a specialist for additional investigations or treatment.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default NeckPain